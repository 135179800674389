import React from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import { useUserContext } from '../context/UserContext'; // Import the UserContext

const SimpleNavBar = () => {
    const { adminSessionToken } = useUserContext(); // Access adminSessionToken from the context

    return (
        <Navbar bg="dark" variant="dark" expand="lg" className="mb-4">
            <Container>
                <Navbar.Brand href="https://backrooms.net" target="_blank" rel="noopener noreferrer">Backrooms dot Net</Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">
                        <Nav.Link href="/">Backrooms Contents</Nav.Link>
                        <Nav.Link href="/pforacle">pfOracle</Nav.Link>
                        <Nav.Link href="/dice">Dice</Nav.Link>
                        <Nav.Link href="/attributions">ORC Notice</Nav.Link>


                        {/* Conditionally render Admin link */}
                        {adminSessionToken && (
                            <Nav.Link href="/admin">Admin</Nav.Link>
                        )}
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
};

export default SimpleNavBar;
